<template>
  <b-card
    class="earnings-card"
  >
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1">
          Pesanan
        </b-card-title>
        <div class="font-small-2">
          <h4 class="mb-1">
            Total : {{ jumlahorder }}
          </h4>
        </div>
        <b-card-text class="text-muted font-small-2">
          <span> Sepanjang waktu.</span>
        </b-card-text>
      </b-col>
      <b-col cols="6">
        <!-- chart -->
        <vue-apex-charts
          height="120"
          :options="earningsChart.chartOptions"
          :series="earningsChart.series"
        />
      </b-col>

      <!-- Budget pendapatan -->
      <b-col
        cols="6"
        class="mt-2"
      >
        <h4>Pendapatan</h4>
        <b-button
          id="popover-2"
          variant="outline-primary"
          size="sm"
        >
          <small>Lihat Pendapatan</small>
        </b-button>
        <b-popover
          target="popover-2"
          title="Total Pendapatan"
          triggers="hover focus"
          placement="bottom"
          :content="`Rp. ${jumlahpendapatan}`"
          class="text-center"
        />
        <br>
        <small class="text-muted">Sepanjang waktu.</small>
      </b-col>
      <b-col
        cols="6"
        class="mt-3"
      >
        <vue-apex-charts
          id="budget-chart"
          class="text-right"
          type="line"
          height="80"
          :options="budgetChart.options"
          :series="budgetChart.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText,
  BPopover, BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
    BPopover,
  },
  props: ['jumlahorder', 'jumlahpendapatan', 'persenbatal', 'persentertunda', 'persenselesai'],
  data() {
    return {
      earningsChart: {
        series: [this.persenselesai, this.persentertunda, this.persenbatal],
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: ['Selesai', 'Tertunda', 'Batal'],
          stroke: { width: 0 },
          colors: [$themeColors.success, $themeColors.warning, $themeColors.danger],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: false,
                    offsetY: 15,
                    label: 'Selesai',
                    formatter() {
                      return this.orderselesai
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },

      // budget chart
      budgetChart: {
        series: [
          {
            data: [60, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
          },
          {
            data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
          },
        ],
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            sparkline: { enabled: true },
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 5],
            width: [2],
          },
          colors: [$themeColors.primary, '#dcdae3'],
          tooltip: {
            enabled: false,
          },
        },
      },
    }
  },
}
</script>
