<template>
  <b-card
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="8"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            Statistik Pesanan
          </h4>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
              <span>Selesai</span>
            </div>
            <div class="d-flex align-items-center">
              <span class="bullet bullet-danger svg-font-small-3 mr-50 cursor-pointer" />
              <span>Batal</span>
            </div>
          </div>
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          type="bar"
          height="230"
          :options="revenueReport.chartOptions"
          :series="revenueReport.series"
        />
      </b-col>
      <b-col
        md="4"
      >
        <b-card
          no-body
        >
          <h4 class="m-1">
            Aktifkan fitur
          </h4>
          <div class="ml-1">
            <span>Show Pulsa</span>
            <br>
            <b-form-radio-group
              id="btn-radios-1"
              v-model="selectedPulsa"
              :button-variant="selectedPulsa ? 'success' : 'danger'"
              :options="optionsPulsa"
              buttons
              name="radios-btn-default"
              @change="fiturPulsa()"
            />
          </div>
          <div class="mt-1 ml-1">
            <span>Show Reservasi</span>
            <br>
            <b-form-radio-group
              id="btn-radios-1"
              v-model="selectedReservasi"
              :button-variant="selectedReservasi ? 'success' : 'danger'"
              :options="optionsReservasi"
              buttons
              name="radios-btn-default"
              @change="fiturReservasi()"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormRadioGroup,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { db } from '@/firebase'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  props: ['jumlahpendapatan'],
  data() {
    return {
      selectedPulsa: '',
      optionsPulsa: [
        { text: 'Aktif', value: true },
        { text: 'Non-aktif', value: false },
      ],
      selectedReservasi: '',
      optionsReservasi: [
        { text: 'Aktif', value: true },
        { text: 'Non-aktif', value: false },
      ],
      fitur: '',
      revenue_report: {},
      revenueReport: {
        series: [],
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.86rem',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.primary, $themeColors.danger],
          plotOptions: {
            bar: {
              columnWidth: '17%',
              endingShape: 'rounded',
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.86rem',
              },
            },
          },
        },
      },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      db.collection('appConfig').doc('web_hub').collection('statistic_orders').orderBy('waktu', 'asc')
        .get()
        .then(snapshot => {
          const selesai = []
          const batal = []
          snapshot.docs.forEach(docs => {
            const data = docs.data()
            this.revenueReport.chartOptions.xaxis.categories.push(docs.id)
            selesai.push(data.selesai)
            batal.push(-data.batal)
          })

          this.revenueReport.series = [
            {
              name: 'Selesai',
              data: selesai,
            },
            {
              name: 'Batal',
              data: batal,
            },
          ]
        })
    },
    fiturPulsa() {
      db.collection('appConfig').doc('mobile_mitra').update({
        showPulsa: this.selectedPulsa,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Fitur pulsa berhasil ${this.selectedPulsa}`,
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      })
    },
    fiturReservasi() {
      db.collection('appConfig').doc('mobile_customer').update({
        showReservasi: this.selectedReservasi,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Fitur reservasi berhasil ${this.selectedReservasi}`,
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      })
    },
  },
}
</script>
