<template>
  <div>
    <app-breadcrumb />
    <b-row>
      <b-col md="12">
        <b-card-actions
          title="Selamat Datang di MECAREHUB 🚀"
          action-close
        >
          <b-card-text>
            Halo, {{ greeting }} semoga harimu menyenangkan!!!
          </b-card-text>
        </b-card-actions>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <card-statistic-order-chart
          v-if="jumlah_mitra != 0 && permission.dashboardRead"
          :jumlahorder="jumlah_order"
          :orderselesai="order_selesai"
          :orderbatal="order_batal"
          :ordertertunda="order_tertunda"
          :jumlahpendapatan="jumlah_pendapatan"
          :persenbatal="Math.ceil(totalPesananBatal)"
          :persentertunda="Math.ceil(totalPesananTertunda)"
          :persenselesai="Math.ceil(totalPesananSelesai)"
        />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <card-statistics-group
          v-if="jumlah_mitra != 0 && permission.dashboardRead"
          :mitra="jumlah_mitra"
          :customer="jumlah_customer"
          :customerpria="customer_pria"
          :customerwanita="customer_wanita"
          :mitrapria="mitra_pria"
          :mitrawanita="mitra_wanita"
          :orderselesai="order_selesai"
          :orderbatal="order_batal"
          :ordertertunda="order_tertunda"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <card-analytic-revenue-report
          v-if="permission.dashboardRead"
          :jumlahpendapatan="jumlah_pendapatan"
        />
      </b-col>
    </b-row>
    <b-container class="bv-example-row" />
  </div>
</template>
<script>

import {
  BCardText, BRow, BCol, BContainer,
} from 'bootstrap-vue'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { db } from '@/firebase'
import permission from '@/permission'
import CardStatisticsGroup from './CardStatisticsGroup.vue'
import CardAnalyticRevenueReport from './CardAnalyticRevenueReport.vue'
import CardStatisticOrderChart from './CardStatisticOrderChart.vue'

export default {
  components: {
    BCardActions,
    BCardText,
    BRow,
    BCol,
    BContainer,
    AppBreadcrumb,
    CardStatisticsGroup,
    CardAnalyticRevenueReport,
    CardStatisticOrderChart,
  },
  data() {
    return {
      permission: permission.access,
      greeting: '',
      jumlah_mitra: 0,
      jumlah_customer: 0,
      customer_pria: 0,
      customer_wanita: 0,
      mitra_pria: 0,
      mitra_wanita: 0,
      jumlah_order: 0,
      order_selesai: 0,
      order_batal: 0,
      order_tertunda: 0,
      jumlah_pendapatan: 0,
    }
  },
  created() {
    this.greetings()
    this.getGreetings()

    if (!localStorage.getItem('reloaded')) {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem('reloaded', '1')
      window.location.reload()
    }
  },
  methods: {
    greetings() {
      const d = new Date()
      const hrs = d.getHours()
      if (hrs < 12) {
        this.greeting = 'Selamat Pagi'
      } else if (hrs >= 12 && hrs <= 15) {
        this.greeting = 'Selamat Siang'
      } else if (hrs >= 15 && hrs <= 17) {
        this.greeting = 'Selamat Sore'
      } else if (hrs >= 17 && hrs <= 24) {
        this.greeting = 'Selamat Malam'
      }
    },
    async getGreetings() {
      const persen = 100

      await db.collection('appConfig').doc('web_hub').get().then(snapshot => {
        this.jumlah_mitra = snapshot.data().jumlah_mitra
        this.jumlah_customer = snapshot.data().jumlah_customer
        this.customer_pria = snapshot.data().customer_pria
        this.customer_wanita = snapshot.data().customer_wanita
        this.mitra_pria = snapshot.data().mitra_pria
        this.mitra_wanita = snapshot.data().mitra_wanita
        this.jumlah_order = snapshot.data().jumlah_order
        this.order_selesai = snapshot.data().order_selesai
        this.order_batal = snapshot.data().order_batal
        this.order_tertunda = snapshot.data().order_tertunda
        this.jumlah_pendapatan = snapshot.data().jumlah_pendapatan

        const total = this.order_batal + this.order_selesai + this.order_tertunda

        this.totalPesananBatal = (this.order_batal / total) * persen
        this.totalPesananTertunda = (this.order_tertunda / total) * persen
        this.totalPesananSelesai = (this.order_selesai / total) * persen
      })
    },
  },
}
</script>

<style>

</style>
